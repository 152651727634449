import { Component, Inject, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { RELATIONSHIP_TYPES } from 'src/app/shared/utils';
import { CustomValidators } from 'src/app/shared/validators/custom-validators';
import { AddFamilyMemberData } from './add-family-member-data.interface';
import { format as formatRut } from 'rut.js';

@Component({
  selector: 'app-add-family-member',
  templateUrl: './add-family-member.component.html',
  styleUrls: ['./add-family-member.component.scss']
})
export class AddFamilyMemberComponent implements OnInit {

  public relationshipTypes = RELATIONSHIP_TYPES;
  public addFamilyForm: FormGroup;

  constructor(
    private formBuilder: FormBuilder,
    public dialogRef: MatDialogRef<AddFamilyMemberComponent>,
    @Inject(MAT_DIALOG_DATA) public data: AddFamilyMemberData
  ) {

    this.addFamilyForm = this.formBuilder.group({
      rut: [this.data?.rut, [CustomValidators.isRutValid]],
      name: [this.data?.name, Validators.required],
      last_name: [this.data?.last_name, Validators.required],
      relationship: [this.data?.relationship, Validators.required],
      birthdate: [this.data?.birthdate, Validators.required],
    });
  }

  get rut() { return this.addFamilyForm.get('rut') }
  get name() { return this.addFamilyForm.get('name') }
  get last_name() { return this.addFamilyForm.get('last_name') }
  get relationship() { return this.addFamilyForm.get('relationship') }
  get birthdate() { return this.addFamilyForm.get('birthdate') }

  ngOnInit(): void {
  }

  public setFamilyMemberValues() {
    this.addData();
  }

  callingFunction() {
    console.log(this.addFamilyForm.value);
  }

  addData(): void {
    this.dialogRef.close(this.addFamilyForm.value);
  }

  onNoClick(): void {
    this.dialogRef.close();
  }

  public onValueChange() {
    return this.rut?.setValue(formatRut(this.rut?.value));
  }

}
