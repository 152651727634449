import { Component, OnInit } from '@angular/core';
import Swal from 'sweetalert2';
import {InsuredDeclarationService} from '../insured-declaration/insured-declaration.service';
import exportFromJSON from 'export-from-json';
import {FamilyIntegrant} from '../shared/interfaces/family-integrant.interface';
@Component({
  selector: 'app-download-data',
  templateUrl: './download-data.component.html',
  styleUrls: ['./download-data.component.scss']
})
export class DownloadDataComponent implements OnInit {
  isLoading = false;
  error = false;
  constructor(private personService: InsuredDeclarationService) { }

  ngOnInit(): void {

  }

  getFamily(familyGroups: FamilyIntegrant[], maxFamilySize: number): object {
    const obj = {};
    for (let i = 0; i < maxFamilySize; i++){
      obj[`familiar_nombre_${i}`] = familyGroups[i] && familyGroups[i].name;
      obj[`familiar_rut_${i}`] = familyGroups[i] && familyGroups[i].rut;
      obj[`familiar_fecha_nacimiento_${i}`] = familyGroups[i] && familyGroups[i].birthdate;
      obj[`familiar_parentesco_${i}`] = familyGroups[i] && familyGroups[i].relationship;
    }
    return obj;
  }

  downloadData(): void {
    Swal.fire({
      input: 'password',
      title: 'Descargar base',
      text: 'Para descargar la base de datos, debes proporcionar la contraseña de seguridad',
      icon: 'warning',
      confirmButtonText: 'Descargar!',
    }).then((result) => {
      if (result.value && result.value === '2021$Colmed$%!') {
        this.isLoading = true;
        this.personService.getPeople().subscribe(response => {
          const data = [];
          response.data.forEach(itemobj => {
            itemobj.attributes.family_groups.forEach(familyItem => {
              data.push({
                Nombre: itemobj.attributes.name,
                Rut: itemobj.attributes.rut,
                'Fecha de nacimiento': itemobj.attributes.birthdate,
                Direccion: itemobj.attributes.address,
                Comuna: itemobj.attributes.commune,
                Region: itemobj.attributes.region,
                Email: itemobj.attributes.email,
                Telefono: itemobj.attributes.phone,
                'Estado civil': itemobj.attributes.civil_status,
                'Sistema medico': itemobj.attributes.health_system,
                familiar_nombre: familyItem.name,
                familiar_rut: familyItem.rut,
                familiar_fecha_nacimiento: familyItem.birthdate,
                familiar_parentesco: familyItem.relationship
              });
            });
          });

          exportFromJSON(
            // @ts-ignore
            {
              data,
              fileName: `base-de-datos-${new Date()}`,
              exportType: 'xls'
            });
          this.isLoading = false;
        });
      }else{
        this.error = true;
      }
    });
  }

  uploadData(event): void {
    const fileReaded = event[0];

    const reader: FileReader = new FileReader();
    reader.readAsText(fileReaded);

    reader.onload = (e) => {
      const csv = reader.result;
      // @ts-ignore
      const allTextLines = csv.split(/\r|\n|\r/);
      const headers = allTextLines[0].split(',');
      const lines = [];

      // tslint:disable-next-line:prefer-for-of
      for (let i = 0; i < allTextLines.length; i++) {
        const data = allTextLines[i].split(',');
        if (data.length === headers.length) {
          const tarr = [];
          for (let j = 0; j < headers.length; j++) {
            tarr.push(data[j]);
          }

          lines.push(tarr);
        }
      }
      // all rows in the csv file
      const dataRow = lines.slice(1);
      const dataMapped = dataRow.map((item) => ({rut: item[0], name: item[1]}));
      this.personService.postMassiveImport(dataMapped).subscribe((result) => {
        Swal.fire({
          title: 'OK',
          text: 'Se ha cargado los daatos de forma exitosa',
          icon: 'success',
          confirmButtonText: 'OK',
        });
      }, () => {
        Swal.fire({
          title: 'Error',
          text: 'No se pudo cargar los datos',
          icon: 'error',
          confirmButtonText: 'OK',
        });
      });
    };
  }
}
