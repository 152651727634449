<div class="content" role="main">
  <app-stepper [stepperData]="stepperData"></app-stepper>
  <div *ngIf="!completed" class="insert__title">
    <div class="insert__name">
      <h3 class="insert__border">RESUMEN DE TU INFORMACIÓN</h3>
    </div>
    <div class="insert__form">
      <div class="insert__input">
        <h4>RUT</h4>
        <p>{{rut}}</p>
      </div>
      <div class="insert__input">
        <h4>NOMBRE</h4>
        <p>{{name}}</p>
      </div>
      <div class="insert__input">
        <h4>FECHA DE NACIMIENTO</h4>
        <p>{{oneStepData.birthdate}}</p>
      </div>
    </div>
    <div class="insert__form">
      <div class="insert__input">
        <h4>ESTADO CIVIL</h4>
        <p>{{oneStepData.civil_status}}</p>
      </div>
      <div class="insert__input">
        <h4>CORREO ELECTRÓNICO</h4>
        <p>{{oneStepData.email}}</p>
      </div>
      <div class="insert__input">
        <h4>TELÉFONO</h4>
        <p>{{oneStepData.phone}}</p>
      </div>
    </div>
    <div class="insert__form">
      <div class="insert__input">
        <h4>COMUNA</h4>
        <p>{{oneStepData.commune}}</p>
      </div>
      <div class="insert__input">
        <h4>REGIÓN</h4>
        <p>{{oneStepData.region.region}}</p>
      </div>
      <div class="insert__input">
        <h4>DIRECCIÓN</h4>
        <p>{{oneStepData.address}}</p>
      </div>
    </div>

    <div class="insert__name">
      <h3 class="insert__border">BENEFICIARIOS</h3>
    </div>
    <div class="full-table">
      <app-data-table title="familyGroup" [columns]="columns" [data]="dataSource" [loading]="false"
        [showPagination]="false" [withFooter]="false" [noDataMessage]="noDataMessage" rowClass="primary">
      </app-data-table>
    </div>


    <!--    <div class="insert__title">-->
    <!--      <div class="insert__name">-->
    <!--        <h2>DECLARACIÓN</h2>-->
    <!--        <h4>-->
    <!--          A continuación debe declarar cualquier enfermedad o patología conocida o-->
    <!--          diagnosticada a usted y/o al grupo familiar que desea incorporar el-->
    <!--          seguro de salud.-->
    <!--        </h4>-->
    <!--      </div>-->
    <!--      <div class="insert__name">-->
    <!--        <h3 style="background-color: #eeeeee">-->
    <!--          SELECCIONA EL TIPO DE DECLARACIÓN-->
    <!--        </h3>-->

    <!--        <mat-radio-group-->
    <!--          aria-labelledby="radio-group-label"-->
    <!--          class="radio-group"-->
    <!--          [(ngModel)]="declarationSelected"-->
    <!--        >-->
    <!--          <mat-radio-button-->
    <!--            class="radio-button"-->
    <!--            *ngFor="let declaration of declarationType"-->
    <!--            [value]="declaration"-->
    <!--          >-->
    <!--            <h4>{{ declaration }}</h4>-->
    <!--          </mat-radio-button>-->
    <!--        </mat-radio-group>-->
    <!--      </div>-->
    <!--    <div class="insert__name">-->
    <!--      <h3 class="insert__border">RESUMEN GRUPO FAMILIAR</h3>-->
    <!--    </div>-->
    <!--    <div class="insert__form">-->
    <!--      <div class="insert__input">-->
    <!--        <h4>RUT</h4>-->
    <!--        <p>16.758.585-K</p>-->
    <!--      </div>-->
    <!--      <div class="insert__input">-->
    <!--        <h4>NOMBRE</h4>-->
    <!--        <p>hector alejandro martinez pontigo</p>-->
    <!--      </div>-->
    <!--      <div class="insert__input">-->
    <!--        <h4>PARENTESCO</h4>-->
    <!--        <p>02-02-1988</p>-->
    <!--      </div>-->
    <!--    </div>-->
    <!--    <div class="insert__name">-->
    <!--      <h3 class="insert__border">RESUMEN BENEFICIARIOS</h3>-->
    <!--    </div>-->
    <!--    <div class="insert__form">-->
    <!--      <div class="insert__input">-->
    <!--        <h4>RUT</h4>-->
    <!--        <p>SIN INFORMACIÓN</p>-->
    <!--      </div>-->
    <!--      <div class="insert__input">-->
    <!--        <h4>NOMBRE</h4>-->
    <!--        <p></p>-->
    <!--      </div>-->
    <!--      <div class="insert__input">-->
    <!--        <h4>PORCENTAJE %</h4>-->
    <!--        <p></p>-->
    <!--      </div>-->
    <!--    </div>-->
    <div class="insert__button-section">
      <button type="button" mat-flat-button color="accent" (click)="goEdit()" [disabled]="isLoading">
        Editar
      </button>
      <button type="submit" mat-flat-button color="primary" (click)="goSuccess()" [disabled]="isLoading">
        <mat-spinner color="accent" [diameter]="35" *ngIf="isLoading"></mat-spinner>
        <span *ngIf="!isLoading">Aceptar</span>
      </button>
    </div>
  </div>
  <div *ngIf="completed">
    <div class="insert__title">
      <div class="insert__name">
        <h3>RESUMEN DECLARACIONES</h3>
        <h4>
          A continuación debe declarar cualquier enfermedad o patología conocida o
          diagnosticada a usted y/o al grupo familiar que desea incorporar el
          seguro de salud.
        </h4>
      </div>
      <div class="insert__name">
        <h3 style="background-color: #eeeeee">
          SELECCIONA EL TIPO DE DECLARACIÓN
        </h3>

        <mat-radio-group aria-labelledby="radio-group-label" class="radio-group" [(ngModel)]="declarationSelected">
          <mat-radio-button class="radio-button" *ngFor="let declaration of declarationType" [value]="declaration">
            <h4>{{ declaration }}</h4>
          </mat-radio-button>
        </mat-radio-group>

        <mat-form-field class="insert__input" appearance="legacy">
          <h4>NOMBRE</h4>
          <mat-select [(ngModel)]="nameDeclaration">
            <mat-option *ngFor="let person of people" [value]="person">{{person}}</mat-option>
          </mat-select>
        </mat-form-field>

        <div class="insert__form">
          <div class="insert__form">
            <mat-form-field class="insert__input">
              <h4>DETALLE</h4>
              <input required matInput placeholder="" type="text" [(ngModel)]="detail" color="primary"
                maxlength="255" />
            </mat-form-field>
          </div>
        </div>
        <!--     <div class="insert__button-section insert__button-section--double">
        <button type="submit" mat-flat-button color="accent" href="/document">
          Cancelar
        </button>
        <button type="submit" mat-flat-button color="accent" href="/document">
          Guardar declaración
        </button>
      </div> -->
        <button type="submit" mat-flat-button color="primary" (click)="addDeclaration()" [disabled]="isLoading">
          <mat-spinner color="accent" [diameter]="35" *ngIf="isLoading"></mat-spinner>
          <span *ngIf="!isLoading">Agregar</span>
        </button>
        <div class="full-table">
          <app-data-table title="familyGroup" [columns]="columnsDeclaration" [data]="declarations" [loading]="false"
            [showPagination]="false" [withFooter]="false" [noDataMessage]="noDataMessage" rowClass="primary">
          </app-data-table>
        </div>
        <div class="insert__button-section insert__button-section--double">
          <button type="button" mat-flat-button color="accent" (click)="goEdit()" [disabled]="isLoading">
            Editar
          </button>
          <button type="submit" mat-flat-button color="primary" (click)="goSuccess()" [disabled]="isLoading">
            <mat-spinner color="accent" [diameter]="35" *ngIf="isLoading"></mat-spinner>
            <span *ngIf="!isLoading">Aceptar</span>
          </button>
        </div>
      </div>
    </div>
  </div>