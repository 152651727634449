import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';

import { ActionButtonsComponent } from './action-buttons.component';
import { ButtonModule } from '../button/button.module';
import { ButtonGroupModule } from '../button-group/button-group.module';

@NgModule({
  imports: [CommonModule, MatProgressSpinnerModule, ButtonModule, ButtonGroupModule],
  exports: [ActionButtonsComponent],
  declarations: [ActionButtonsComponent],
})
export class ActionButtonsModule { }
