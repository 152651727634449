import { Component, OnInit } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { Router } from '@angular/router';
import { StepperData } from '../shared/stepper/stepper-data.interface';
import { DataTableColumn, DataTableSourceItem } from '../shared/table/data-table/data-table.model';
import { STEPS } from '../shared/utils';
import { AddBeneficiaryData } from './add-beneficiary/add-beneficiary-data.interface';
import { AddBeneficiaryComponent } from './add-beneficiary/add-beneficiary.component';

@Component({
  selector: 'app-summary-beneficiary',
  templateUrl: './summary-beneficiary.component.html',
  styleUrls: ['./summary-beneficiary.component.scss']
})
export class SummaryBeneficiaryComponent implements OnInit {
  public stepperData: StepperData[] = STEPS;
  public beneficiaryData: AddBeneficiaryData;
  public columns: DataTableColumn[] = [
    {
      id: 'rut',
      key: 'rut',
      header: 'RUT'
    },
    {
      id: 'name',
      key: 'name',
      header: 'NOMBRE'
    },
    {
      id: 'relationship',
      key: 'relationship',
      header: 'PARENTESCO'
    },
    {
      id: 'percentage',
      key: 'percentage',
      header: 'PORCENTAJE'
    },
  ];
  public noDataMessage: string = 'NO EXISTEN PERSONAS AGREGADAS COMO BENEFICIARIOS';
  public dataSource: DataTableSourceItem[] = [];
  public loadingTable = false;

  constructor(private router: Router, public dialog: MatDialog) {
    this.stepperData[2].isActive = true;
    this.beneficiaryData = this.getEmptyBeneficiaryData();
  }

  ngOnInit(): void {
  }

  getEmptyBeneficiaryData() {
    const empty: AddBeneficiaryData =
      { rut: '', name: '', percentage: 0, relationship: '' };
    return empty;
  }

  public goBack() {
    this.router.navigate(['/family-group']);
  }

  public goDeclarations() {
    this.router.navigate(['/summary-declarations']);
  }

  public openAddBeneficiary() {
    const dialogRef = this.dialog.open(AddBeneficiaryComponent, {
      width: '600px',
      height: '590px',
      data: this.beneficiaryData
    });

    dialogRef.afterClosed().subscribe(result => {
      console.log('The dialog was closed');
      console.log('The dialog was closed');
      this.loadingTable = true;
      if (result) {
        console.log(result);
        let temporal: AddBeneficiaryData[] = [];
        temporal.push(result);
        this.dataSource = temporal;
        //this.dataSource.push(...result);
        this.loadingTable = false;

      }
      this.beneficiaryData = this.getEmptyBeneficiaryData();
    });
  }

}
