<div class="content" role="main">
  <div class="icon-success">
    <mat-icon [inline]="true" aria-hidden="false" aria-label="" color="secondary">check_circle_outline</mat-icon>
  </div>
  <div class="insert">
    <div class="insert__title">
      <h3>PRE-INSCRIPCIÓN REALIZADA CON ÉXITO, PRONTO</h3>
      <h3>NOS COMUNICAREMOS CONTIGO</h3>
      <h4>TU CÓDIGO DE REGISTRO ES:</h4>
      <div class="register-number">
        <h3>000000{{registerNumber}}</h3>
      </div>
      <h3>ENVIAMOS UNA COPIA DEL REGISTRO A</h3>
      <h3>{{data?.email}}</h3>
    </div>
  </div>

  <div class="insert__button-section">
    <button type="submit" mat-flat-button color="accent" (click)="goHome()">
      Inicio
    </button>
  </div>
</div>