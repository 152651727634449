import { Component, Input, OnInit } from '@angular/core';
import { StepperData } from './stepper-data.interface';

@Component({
  selector: 'app-stepper',
  templateUrl: './stepper.component.html',
  styleUrls: ['./stepper.component.scss']
})
export class StepperComponent implements OnInit {
  @Input() stepperData: StepperData[] = [];

  constructor() { }

  ngOnInit(): void {
    const activeIndex = this.stepperData.findIndex(nextStep => nextStep.isActive);
    this.stepperData.forEach((step, index) => {
      if (!step.isActive) {
        step.isActive = activeIndex > index;
      }
    });
  }

  ngOnDestroy() {
    this.stepperData.forEach(step => step.isActive = false);
  }

}
