import { Component, OnInit } from '@angular/core';
import { FormControl } from '@angular/forms';
import { TooltipPosition } from '@angular/material/tooltip';
import { Router } from '@angular/router';

@Component({
  selector: 'app-insert-document',
  templateUrl: './insert-document.component.html',
  styleUrls: ['./insert-document.component.scss']
})
export class InsertDocumentComponent implements OnInit {

  label: string = 'Ej: 123123123'
  value = 'Clear me';
  positionOptions: TooltipPosition[] = ['below', 'above', 'left', 'right'];
  position = new FormControl(this.positionOptions[3]);

  constructor(private router: Router) { }

  ngOnInit(): void {
  }

  public goToInsertDataForm() {
    this.router.navigate(['/form']);
  }

}
