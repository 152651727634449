<div class="content" role="main">
  <h2 class="headline">
    BIENVENIDO AL FORMULARIO DE PRE-INSCRIPCIÓN SEGURO COMPLEMENTARIO DE SALUD FAMILIAR
  </h2>
  <div class="insert">
    <form class="insert__title" [formGroup]="welcomeForm" (ngSubmit)="submitForm()">
      <h3>Ingresa tu RUN y código</h3>
      <mat-form-field class="input-form-field">
        <mat-label>RUT {{ label }}</mat-label>
        <input id="rut" matInput type="text" maxlength="15" formControlName="rut" (input)="onValueChange()" required />

        <button mat-button matSuffix mat-icon-button aria-label="Ingresa tu RUN">
          <mat-icon>close</mat-icon>
        </button>
        <!-- <mat-hint>Errors appear instantly!</mat-hint> -->
        <mat-error *ngIf="rut?.hasError('rutinvalid') && !rut?.hasError('required')">
          Ingrese un RUN válido
        </mat-error>
        <mat-error *ngIf="rut?.hasError('required')">
          RUN es <strong>obligatorio</strong>.
        </mat-error>
      </mat-form-field>

      <mat-form-field class="input-form-field">
        <mat-label>RCM</mat-label>
        <input id="code" matInput type="text" maxlength="15" formControlName="code" (input)="onValueChangeCode()"
          required />

        <button mat-button matSuffix mat-icon-button aria-label="Ingresa tu código">
          <mat-icon>close</mat-icon>
        </button>
        <!-- <mat-hint>Errors appear instantly!</mat-hint> -->
        <mat-error *ngIf="rut?.hasError('rutinvalid') && !rut?.hasError('required')">
          Ingrese un código válido
        </mat-error>
        <mat-error *ngIf="rut?.hasError('required')">
          RCM es <strong>obligatorio</strong>.
        </mat-error>
      </mat-form-field>


      <!-- <app-input-clearable [label]="label"></app-input-clearable> -->
      <button class="insert__button-section" type="submit" mat-flat-button color="primary"
        [disabled]="!welcomeForm.valid || isLoading">
        <mat-spinner *ngIf="isLoading" color="accent" diameter="35"></mat-spinner>
        <span *ngIf="!isLoading">Continuar</span>
      </button>
    </form>
  </div>
</div>