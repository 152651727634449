<div tabindex="-1" class="button--container" [class.disabled]="buttonDisabled" [class.secondary]="color === 'secondary'" [class.primary]="color === 'primary'" [class.danger]="color === 'danger'">
  <div *ngIf="type === 'link'; then link; else button"></div>
  <div class="help-text--container" [class.error]="helpTextError" *ngIf="helpText">{{ helpText }}</div>
</div>

<ng-template #link>
  <a *ngIf="!flat && external" [disableRipple]="buttonDisabled" mat-button [href]="linkUrl" [target]="target" (click)="onClick()" tabindex="-1">
    <ng-container *ngTemplateOutlet="buttonContent"></ng-container>
  </a>
  <a *ngIf="flat && external" [disableRipple]="buttonDisabled" mat-flat-button [href]="linkUrl" [target]="target" (click)="onClick()" tabindex="-1">
    <ng-container *ngTemplateOutlet="buttonContent"></ng-container>
  </a>

  <a *ngIf="!flat && !external" [disableRipple]="buttonDisabled" mat-button [routerLink]="linkUrl" [queryParams]="linkQueryParams" [target]="target" (click)="onClick()" tabindex="-1">
    <ng-container *ngTemplateOutlet="buttonContent"></ng-container>
  </a>
  <a *ngIf="flat && !external" [disableRipple]="buttonDisabled" mat-flat-button [routerLink]="linkUrl" [queryParams]="linkQueryParams" [target]="target" (click)="onClick()" tabindex="-1">
    <ng-container *ngTemplateOutlet="buttonContent"></ng-container>
  </a>
</ng-template>

<ng-template #button>
  <button style="background-color: #333" *ngIf="!flat" [disableRipple]="buttonDisabled" color="primary" mat-button [disabled]="loading" (click)="onClick()" [type]="buttonType" tabindex="-1">
    <ng-container *ngTemplateOutlet="buttonContent"></ng-container>
  </button>
  <button style="background-color: #333" *ngIf="flat" [disableRipple]="buttonDisabled" mat-flat-button [disabled]="loading" (click)="onClick()" [type]="buttonType" tabindex="-1">
    <ng-container *ngTemplateOutlet="buttonContent"></ng-container>
  </button>
</ng-template>

<ng-template #buttonContent>
  <div class="button-content--container" [class.hidden]="loading">
    <ng-content></ng-content>
  </div>
  <div *ngIf="loading" class="spinner--container">
    <mat-spinner></mat-spinner>
  </div>
</ng-template>
