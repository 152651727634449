import { Inject } from '@angular/core';
import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { RELATIONSHIP_TYPES } from 'src/app/shared/utils';
import { CustomValidators } from 'src/app/shared/validators/custom-validators';
import { AddBeneficiaryData } from './add-beneficiary-data.interface';
import { format as formatRut } from 'rut.js'

@Component({
  selector: 'app-add-beneficiary',
  templateUrl: './add-beneficiary.component.html',
  styleUrls: ['./add-beneficiary.component.scss']
})
export class AddBeneficiaryComponent implements OnInit {

  public relationshipTypes = RELATIONSHIP_TYPES;
  public addBeneficiaryForm: FormGroup;

  constructor(
    private formBuilder: FormBuilder,
    public dialogRef: MatDialogRef<AddBeneficiaryComponent>,
    @Inject(MAT_DIALOG_DATA) public data: AddBeneficiaryData) {
    this.addBeneficiaryForm = this.formBuilder.group({
      rut: ['', [Validators.required, CustomValidators.isRutValid]],
      name: ['', Validators.required],
      relationship: ['', Validators.required],
      percentage: ['', Validators.required],
    });
  }
  
  get rut() { return this.addBeneficiaryForm.get('rut') }
  get name() { return this.addBeneficiaryForm.get('name') }
  get relationship() { return this.addBeneficiaryForm.get('relationship') }
  get percentage() { return this.addBeneficiaryForm.get('percentage') }

  ngOnInit(): void {
  }

  public setBeneficiariesValues() {
    console.log(this.addBeneficiaryForm.value);
    localStorage.setItem('stepThreeData', JSON.stringify(this.addBeneficiaryForm.value));
  }

  public setBeneficiaryValues() {
    console.log(this.addBeneficiaryForm.value);
    //localStorage.setItem('stepTwoData', JSON.stringify(this.addFamilyForm.value));
    this.addData();
  }

  callingFunction() {
    console.log(this.addBeneficiaryForm.value);
  }

  addData(): void {
    this.dialogRef.close(this.addBeneficiaryForm.value);
  }

  onNoClick(): void {
    this.dialogRef.close();
  }

  public onValueChange() {
    return this.rut?.setValue(formatRut(this.rut?.value));
  }

}
