<div class="content" role="main">
  <app-stepper [stepperData]="stepperData"></app-stepper>
  <div class="insert__title">
    <div class="insert__name">
      <h3>RESUMEN DECLARACIONES</h3>
      <h4>
        A continuación debe declarar cualquier enfermedad o patología conocida o
        diagnosticada a usted y/o al grupo familiar que desea incorporar el
        seguro de salud.
      </h4>
    </div>
    <div class="insert__name">
      <h3 style="background-color: #eeeeee">
        SELECCIONA EL TIPO DE DECLARACIÓN
      </h3>

      <mat-radio-group
        aria-labelledby="radio-group-label"
        class="radio-group"
        [(ngModel)]="declarationSelected"
      >
        <mat-radio-button
          class="radio-button"
          *ngFor="let declaration of declarationType"
          [value]="declaration"
        >
          <h4>{{ declaration }}</h4>
        </mat-radio-button>
      </mat-radio-group>
    </div>
    <!--     <div class="insert__button-section insert__button-section--double">
      <button type="submit" mat-flat-button color="accent" href="/document">
        Cancelar
      </button>
      <button type="submit" mat-flat-button color="accent" href="/document">
        Guardar declaración
      </button>
    </div> -->
    <div class="insert__button-section insert__button-section--double">
      <button type="submit" mat-flat-button color="accent" (click)="goBack()">
        Volver
      </button>
      <button
        type="submit"
        mat-flat-button
        color="primary"
        (click)="goInformation()"
      >
        Avanzar
      </button>
    </div>
  </div>
</div>
