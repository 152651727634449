<div class="content" role="main">
  <app-stepper [stepperData]="stepperData"></app-stepper>
  <div class="insert__title">
    <div class="insert__name">
      <h3>RESUMEN BENEFICIARIOS</h3>
      <h4>
        Indique la o las personas que designará como beneficiarios del seguro de
        vida. Es decir a quien pagaremos el beneficio en caso de que usted
        fallezca
      </h4>
    </div>
    <div class="insert__button-section">
      <button
        type="submit"
        mat-flat-button
        color="accent"
        (click)="openAddBeneficiary()"
      >
        Agregar beneficiario
      </button>
    </div>
    <div class="table">
      <app-data-table
        title="beneficiaries"
        [columns]="columns"
        [data]="dataSource"
        [loading]="loadingTable"
        [showPagination]="false"
        [withFooter]="false"
        [noDataMessage]="noDataMessage"
        rowClass="primary"
      ></app-data-table>
    </div>
    <div class="insert__button-section insert__button-section--double">
      <button type="submit" mat-flat-button color="accent" (click)="goBack()">
        Volver
      </button>
      <button
        type="submit"
        mat-flat-button
        color="primary"
        (click)="goDeclarations()"
      >
        Avanzar
      </button>
    </div>
  </div>
</div>
