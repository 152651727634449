import { Component, Output, Input, EventEmitter } from '@angular/core';

@Component({
  selector: 'app-edit-button',
  templateUrl: './edit-button.component.html',
  styleUrls: ['./edit-button.component.scss']
})
export class EditButtonComponent {
  @Input() disabled = false;
  @Input() size: 'normal' | 'large' = 'normal';
  @Output() clicked = new EventEmitter();
}
