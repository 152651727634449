import { Component, OnInit } from '@angular/core';
// import { FormBuilder, FormControl, FormGroup, FormGroupDirective, NgForm, Validators } from '@angular/forms';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { format as formatRut } from 'rut.js';
import { CustomValidators } from '../shared/validators/custom-validators';
import {WelcomeService} from './welcome.service';
import Swal from 'sweetalert2';
// import { ErrorStateMatcher } from '@angular/material/core';

/** Error when invalid control is dirty, touched, or submitted. */
/* export class MyErrorStateMatcher implements ErrorStateMatcher {
  isErrorState(control: FormControl | null, form: FormGroupDirective | NgForm | null): boolean {
    const isSubmitted = form && form.submitted;
    return !!(control && control.invalid && (control.dirty || control.touched || isSubmitted));
  }
} */
@Component({
  selector: 'app-welcome',
  templateUrl: './welcome.component.html',
  styleUrls: ['./welcome.component.scss']
})
export class WelcomeComponent implements OnInit {
  public welcomeForm: FormGroup;
  label = 'Ej: XX.XXX.XXX-X';
  isLoading = false;

  constructor(private router: Router,
              private formBuilder: FormBuilder,
              private welcomeService: WelcomeService) {
    this.welcomeForm = this.formBuilder.group({
      rut: ['', [Validators.required, CustomValidators.isRutValid]],
      code: ['', [Validators.required]],
    });
  }

  ngOnInit(): void {

  }

  get rut() { return this.welcomeForm.get('rut'); }
  get code() { return this.welcomeForm.get('code'); }


  public onValueChange() {
    return this.rut?.setValue(formatRut(this.rut?.value));
  }

  public onValueChangeCode() {
    return this.code?.setValue(this.code?.value);
  }

  public cleanValue() {
    return this.rut?.setValue('');
  }

  public goInsertDocument() {
    this.router.navigate(['/form']);
  }

  public submitForm() {
    this.isLoading = true;
    const rut = this.welcomeForm.value.rut;
    localStorage.clear();
    this.welcomeService.getPersonByCode(this.rut?.value?.replaceAll('.', '')?.replaceAll('-', ''), this.code.value).subscribe((result) => {
      if (result) {
        if (result.data.attributes.rut && result.data.attributes.name) {
          localStorage.setItem('rut', result.data.attributes.rut);
          localStorage.setItem('name', result.data.attributes.name);
          localStorage.setItem('birthdate', result.data.attributes.birthdate);
          localStorage.setItem('address', result.data.attributes.address);
          this.goInsertDocument();
          this.isLoading = false;
        }

        this.welcomeService.getPerson(rut).subscribe((response) => {
          localStorage.setItem('rut', rut);
          if (response.data.name) {
            localStorage.setItem('name', response.data.name);
          }

          this.goInsertDocument();
          this.isLoading = false;
        });
      }else{
        this.isLoading = false;
        Swal.fire({
          title: 'Error',
          text: 'Sus datos no existen en nuestra base de datos',
          icon: 'error',
          confirmButtonText: 'OK',
        });
      }
    }, () => {
      this.isLoading = false;
      Swal.fire({
        title: 'Error',
        text: 'Sus datos no existen en nuestra base de datos',
        icon: 'error',
        confirmButtonText: 'OK',
      });
    });
  }
}
