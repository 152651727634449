import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { StepperData } from '../shared/stepper/stepper-data.interface';
import { STEPS } from '../shared/utils';

@Component({
  selector: 'app-summary-declarations',
  templateUrl: './summary-declarations.component.html',
  styleUrls: ['./summary-declarations.component.scss']
})
export class SummaryDeclarationsComponent implements OnInit {
  public declarationSelected: string = '';
  public declarationType: string[] = ['ENFERMEDAD, EMBARAZO O ACCIDENTE', 'ACTIVIDAD O DEPORTE RIESGOSO'];
  public stepperData: StepperData[] = STEPS;

  constructor(private router: Router) {
    this.stepperData[3].isActive = true;
  }

  ngOnInit(): void {
  }

  public goBack() {
    this.router.navigate(['/beneficiary']);
  }

  public goInformation() {
    this.router.navigate(['/information']);
  }

}

