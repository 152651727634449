import { MatCardModule } from '@angular/material/card';
import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { CardComponent } from './card.component';
import { ActionButtonsModule } from '../action-buttons/action-buttons.module';
import { EditButtonModule } from '../edit-button/edit-button.module';

@NgModule({
  imports: [CommonModule, MatCardModule, ActionButtonsModule, EditButtonModule],
  declarations: [CardComponent],
  exports: [CardComponent]
})
export class CardModule { }
