import { NgModule, Input } from '@angular/core';
import { CommonModule } from '@angular/common';
import { MatButtonModule } from '@angular/material/button';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { RouterModule } from '@angular/router';

import { ButtonComponent } from './button.component';

@NgModule({
  imports: [CommonModule, MatButtonModule, MatProgressSpinnerModule, RouterModule],
  declarations: [ButtonComponent],
  exports: [ButtonComponent]
})
export class ButtonModule { }
