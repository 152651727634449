import { Component, Input, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { ACCOUNT_TYPES, BANKS, HEALTHCARE_SYSTEM, MARITAL_STATUS, REGIONS, STEPS } from '../shared/utils';
import { StepperData } from '../shared/stepper/stepper-data.interface';
import { Region } from '../shared/interfaces/region.interface';
import { Person } from '../shared/interfaces/person.interface';
import { CustomValidators } from '../shared/validators/custom-validators';
// @ts-ignore
require('angular-datetime-input');


@Component({
  selector: 'app-personal-information',
  templateUrl: './personal-information.component.html',
  styleUrls: ['./personal-information.component.scss']
})
export class PersonalInformationComponent implements OnInit {
  public personalInformationForm: FormGroup;
  public regions: Region[] = REGIONS;
  public banks: string[] = BANKS;
  public accountTypes: string[] = ACCOUNT_TYPES;
  public healthcareSystem: string[] = HEALTHCARE_SYSTEM;
  public maritalStatus: string[] = MARITAL_STATUS;
  public stepperData: StepperData[] = STEPS;
  public communes: string[] = [];
  public personDetail: Person;
  public nameData = this.getFromStorage('name') || this.getFromStorageOne('name');
  public rutData = this.getFromStorage('rut') || this.getFromStorageOne('rut');
  public birthdateData = this.getFromStorageOne('birthdate') || this.formatDate(this.getFromStorage('birthdate'));

  public phoneData = this.getFromStorageOne('phone');
  public addressData = this.getFromStorageOne('address') || this.getFromStorage('address');
  public regionData = this.getFromStorageOne('region');
  public communeData = this.getFromStorageOne('commune');
  public emailData = this.getFromStorageOne('email');
  public civilStatusData = this.getFromStorageOne('civil_status');
  public heatlhSystemData = this.getFromStorageOne('health_system');

  constructor(private formBuilder: FormBuilder, private router: Router) {
    this.stepperData[0].isActive = true;
    this.personDetail = this.getEmptyPerson();


    this.personalInformationForm = this.formBuilder.group({
      name: [{value: this.nameData, disabled: this.nameData}, Validators.required],
      rut: [{value: this.rutData, disabled: this.rutData}, Validators.required],
      birthdate: [this.birthdateData, Validators.required],
      address: [this.clearNull(this.addressData), Validators.required],
      region: [this.regionData.region, Validators.required],
      commune: [this.communeData, Validators.required],
      email: [this.emailData, [Validators.required, CustomValidators.isEmailValid]],
      phone: [this.phoneData, Validators.required],
      civil_status: [this.civilStatusData, Validators.required],
      health_system: [this.heatlhSystemData, Validators.required],
    });
  }

  clearNull(value): string {
    return value === 'null' ? '' : value;
  }

  formatDate(input): string {
    if (!input || input === 'null') {
      return '';
    }
    const datePart = input.match(/\d+/g);
    const year = datePart[0]; // get only two digits
    const month = datePart[1];
    const day = datePart[2];

    return day + '/' + month + '/' + year;
  }

  get name() { return this.personalInformationForm.get('name'); }
  get rut() { return this.personalInformationForm.get('rut'); }
  get birthdate() { return this.personalInformationForm.get('birthdate'); }
  get address() { return this.personalInformationForm.get('address'); }
  get region() { return this.personalInformationForm.get('region'); }
  get commune() { return this.personalInformationForm.get('commune'); }
  get email() { return this.personalInformationForm.get('email'); }
  get phone() { return this.personalInformationForm.get('phone'); }
  get civil_status() { return this.personalInformationForm.get('civil_status'); }
  get health_system() { return this.personalInformationForm.get('health_system'); }


  ngOnInit(): void {
  }

  private getFromStorage(string: string): string {
    return JSON.stringify(localStorage.getItem(string) || '').replace(/"/g, '');
  }

  private getFromStorageOne(string: string): any {
    if (!localStorage.getItem('stepOneData')) {
      return '';
    }
    return JSON.parse(localStorage.getItem('stepOneData') || '')?.[string];
  }

  private getEmptyPerson(): Person {
    const name = this.getFromStorage('name') || '';
    const rut = this.getFromStorage('rut') || '';
    const birthdate = this.getFromStorage('birthdate') || '';
    const person: Person = {
      id: 1,
      name,
      rut,
      birthdate,
      address: '',
      commune: '',
      region: '',
      email: '',
      phone: '',
      civil_status: '',
      health_system: '',
      position: '',
      account_type: '',
      bank: '',
      account_number: ''
    };
    return person;
  }


  public setPersonalInfoValues(): void {
    localStorage.setItem('stepOneData', JSON.stringify(this.personalInformationForm.value));
  }


  public goFamily() {
    this.setPersonalInfoValues();
    this.router.navigate(['/family-group']);
  }

  public selectRegion(region: Region) {
    this.communes = region.communes;
  }

  public get height() {
    return window.innerHeight;
  }

  public get width() {
    return window.innerWidth;
  }


}
