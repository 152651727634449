import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { InsuredDeclarationService } from '../insured-declaration/insured-declaration.service';

@Component({
  selector: 'app-successful-subscription',
  templateUrl: './successful-subscription.component.html',
  styleUrls: ['./successful-subscription.component.scss'],
})
export class SuccessfulSubscriptionComponent implements OnInit {
  data: { [k: string]: any } | undefined;
  registerNumber = 0;
  constructor(
    private router: Router,
    private insuredDeclarationService: InsuredDeclarationService
  ) {
    this.data = router?.getCurrentNavigation()?.extras?.state; // should log out 'bar'
  }

  ngOnInit(): void {
    if (!this.data) {
      this.router.navigate(['/']);
    } else {
      if (!this.data.id) {
        this.registerNumber = 0;
      }
      this.registerNumber = Number(this.data.id) - 30244;
      localStorage.clear();
    }
  }

  public goHome() {
    this.router.navigate(['/']);
  }
}
