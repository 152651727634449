import { Component, Output, EventEmitter, Input, OnInit } from '@angular/core';

@Component({
  selector: 'app-button',
  templateUrl: './button.component.html',
  styleUrls: ['./button.component.scss']
})
export class ButtonComponent implements OnInit {
  @Input() kind: 'primary' | 'primary-text' | 'secondary' | 'secondary-text' | 'danger' | 'danger-text' = 'primary-text';
  @Input() disabled: boolean = false;
  @Input() url: string = '';
  @Input() loading = false;
  @Input() target = '_blank';
  @Input() type = 'button';
  @Input() helpText: string = '';
  @Input() helpTextError = false;
  @Output() clicked = new EventEmitter();
  @Output() inputsHighlighted = new EventEmitter();
  color = 'primary';
  flat: boolean = false;
  external: boolean = false;
  linkUrl: string = '';
  linkQueryParams: {} = {};

  constructor() {

  }

  ngOnInit() {
    this.init();
  }

  init() {
    switch (this.kind) {
      case 'primary':
        this.flat = true;
        this.color = 'primary';
        break;
      case 'secondary':
        this.flat = true;
        this.color = 'secondary';
        break;
      case 'danger':
        this.flat = true;
        this.color = 'danger';
        break;
      case 'primary-text':
        this.flat = false;
        this.color = 'primary';
        break;
      case 'secondary-text':
        this.flat = false;
        this.color = 'secondary';
        break;
      case 'danger-text':
        this.flat = false;
        this.color = 'danger';
        break;
    }
    this.setURL();
  }

  onClick() {
    if (!this.buttonDisabled) {
      return this.clicked.emit();
    }

    this.inputsHighlighted.emit();
  }

  get buttonDisabled() {
    return this.loading || this.disabled;
  }

  get buttonType() {
    return !this.disabled ? this.type : 'button';
  }

  setURL() {
    if (!this.url) {
      return;
    }

    if (this.url.startsWith('http')) {
      this.external = true;
      this.linkUrl = this.url;
    } else {
      this.external = false;
      this.linkUrl = this.url.split('?')[0];
      this.linkQueryParams = this.getQueryParamsFromUrl(this.url.split('?')[1]);
    }
  }

  private getQueryParamsFromUrl(query: string) {
    if (!query) {
      return {};
    }

    const queryParams: { [key: string]: any } = {};
    const params = query.split('&');

    for (let i = 0; i < params.length; i++) {
      const pair = params[i].split('=');
      queryParams[decodeURIComponent(pair[0])] = decodeURIComponent(pair[1]);
    }

    return queryParams;
  }
}
