import { Component, Input, Output, EventEmitter } from '@angular/core';

import { ActionButtonsConfig } from '../action-buttons/action-buttons-config.model';

@Component({
  selector: 'app-card',
  templateUrl: './card.component.html',
  styleUrls: ['./card.component.scss']
})
export class CardComponent {
  @Input() title: string;
  @Input() subtitle: string;
  @Input() showActions = false;
  @Input() showCardImage = false;
  @Input() showHeader = true;
  @Input() showEditButton = false;
  @Input() imageUrl: string;
  @Input() actionButtonsConfig = new ActionButtonsConfig();
  @Output() canceled = new EventEmitter();
  @Output() saved = new EventEmitter();
  @Output() inputsHighlighted = new EventEmitter();
  @Output() edited = new EventEmitter();

  constructor() {
    this.imageUrl = '';
    this.title = '';
    this.subtitle = '';
  }

  onCancel() {
    this.canceled.emit();
  }

  onSave() {
    this.saved.emit();
  }

  onInputsHighlight() {
    this.inputsHighlighted.emit();
  }

  onEditButtonClick() {
    this.edited.emit();
  }
}
