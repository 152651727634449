<div class="content" role="main">
  <app-stepper [stepperData]="stepperData"></app-stepper>
  <div class="insert__title">
    <div class="insert__name">
      <h3>RESUMEN GRUPO FAMILIAR</h3>
      <h4>
        Ingresa la información de los familiares a quienes quieres incluir en tu
        seguro de salud
      </h4>
    </div>
    <div class="insert__button-section">
      <button
        type="submit"
        mat-flat-button
        color="accent"
        (click)="openAddFamilyMember()"
      >
        Agregar familiar
      </button>
    </div>
    <div class="table">
      <app-data-table
        title="familyGroup"
        [columns]="columns"
        [data]="dataSource"
        [loading]="loadingTable"
        [showPagination]="false"
        [withFooter]="false"
        [noDataMessage]="noDataMessage"
        [templateRefs]="[actionsColumn]"
        rowClass="primary"
      ></app-data-table>
      <ng-template #actionsColumn let-birthdate="birthdate">
        <button mat-mini-fab class="update-recommendation-button" color="primary" (click)="editDialog(birthdate)">
          <mat-icon>edit</mat-icon>
        </button>
        <button mat-mini-fab class="delete-recommendation-button" color="warn" (click)="delete(birthdate)">
          <mat-icon>delete</mat-icon>
        </button>
      </ng-template>
    </div>
    <div class="insert__button-section insert__button-section--double">
      <button type="submit" mat-flat-button color="accent" (click)="goBack()">
        Volver
      </button>
      <button
        type="submit"
        mat-flat-button
        color="primary"
        (click)="goFinal()"
      >
        Avanzar
      </button>
    </div>
  </div>
</div>
