import { AbstractControl } from '@angular/forms';
import { validate as validateRut } from 'rut.js';
import { validate as validateEmail } from 'email-validator';
export class CustomValidators {
    public static isRutValid(control: AbstractControl) {
        const value = control.value;
        if (!value) {
          return null;
        }
        if (!validateRut(value)) {
            return {
                rutinvalid: true
            };
        }
        return null;
    }

    public static isEmailValid(control: AbstractControl) {
        const value = control.value;
        if (!validateEmail(value)) {
            return {
                emailinvalid: true
            };
        }
        return null;
    }
}
