<div [ngClass]="{ bordered: showBorder }">
  <app-card class="app-data-table--container">
    <div class="loading--container" *ngIf="loading">
      <mat-spinner diameter="48"></mat-spinner>
    </div>

    <mat-form-field *ngIf="showFilter">
      <input #filterInput matInput (keyup)="onSearch()" placeholder="Filter" />
    </mat-form-field>

    <div class="table--container">
      <div [ngClass]="{ 'hidden-header': hideHeader }">
        <mat-table mat-table [dataSource]="data" matSort>
          <ng-container matColumnDef="select">
            <mat-header-cell *matHeaderCellDef>
              <mat-checkbox
                *ngIf="allowMultiSelect"
                (change)="$event ? masterToggle() : null"
                [checked]="selection.hasValue() && isAllSelected()"
                [indeterminate]="selection.hasValue() && !isAllSelected()"
              >
              </mat-checkbox>
            </mat-header-cell>
            <mat-cell *matCellDef="let row">
              <mat-checkbox
                (click)="$event.stopPropagation()"
                (change)="$event ? selection.toggle(row) : null"
                [checked]="selection.isSelected(row)"
              >
              </mat-checkbox>
            </mat-cell>
          </ng-container>

          <ng-container
            *ngFor="let column of standardColumns"
            [matColumnDef]="column.id"
          >
            <mat-header-cell *matHeaderCellDef>{{
              column.header
            }}</mat-header-cell>
            <mat-cell *matCellDef="let element">
              {{ element[column.key] }}
            </mat-cell>
          </ng-container>

          <ng-container
            *ngFor="let column of customColumns; let index = index"
            [matColumnDef]="column.id"
          >
            <mat-header-cell *matHeaderCellDef>{{
              column.header
            }}</mat-header-cell>
            <mat-cell *matCellDef="let element">
              <ng-container
                *ngTemplateOutlet="templateRefs[index]; context: element"
              ></ng-container>
            </mat-cell>
          </ng-container>

          <mat-header-row
            *matHeaderRowDef="displayedColumns; sticky: true"
          ></mat-header-row>
          <mat-row *matRowDef="let row; columns: displayedColumns"></mat-row>
          <div class="mat-row" *matNoDataRow>
            <div class="mat-cell">{{ noDataMessage }}</div>
          </div>
        </mat-table>
      </div>
    </div>

    <mat-paginator
      *ngIf="showPagination"
      [length]="totalCount"
      [pageSize]="pageSize"
    ></mat-paginator>
    <div *ngIf="withFooter" class="mt-3">
      <ng-content></ng-content>
    </div>
  </app-card>

  <!-- Example of use with custom template:  Replace "property" with property name from the object in ng-template definition line
    <ng-template #dateColumn let-property="property">
      {{ property | ceDate:true }}
    </ng-template>
  -->
</div>
