import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { StepperData } from '../shared/stepper/stepper-data.interface';
import { STEPS } from '../shared/utils';
import {
  DataTableColumn,
  DataTableSourceItem,
} from '../shared/table/data-table/data-table.model';
import { Person } from '../shared/interfaces/person.interface';
import { InsuredDeclarationService } from '../insured-declaration/insured-declaration.service';

@Component({
  selector: 'app-summary-information',
  templateUrl: './summary-information.component.html',
  styleUrls: ['./summary-information.component.scss'],
})
export class SummaryInformationComponent implements OnInit {
  public stepperData: StepperData[] = STEPS;
  oneStepData = JSON.parse(localStorage.getItem('stepOneData') || '{}');
  twoStepData = JSON.parse(localStorage.getItem('stepTwoData') || '[]');
  rut = localStorage.getItem('rut');
  name = localStorage.getItem('name');
  public declarationSelected = '';
  public detail = '';
  public nameDeclaration = '';
  public people = this.twoStepData?.map((i) => i.name);
  public completed = false;
  private declarationData = [];
  public declarationType: string[] = [
    'ENFERMEDAD, EMBARAZO O ACCIDENTE',
    'ACTIVIDAD O DEPORTE RIESGOSO',
  ];
  public declarations: DataTableSourceItem = [];
  public columns: DataTableColumn[] = [
    {
      id: 'rut',
      key: 'rut',
      header: 'RUT',
    },
    {
      id: 'name',
      key: 'name',
      header: 'NOMBRE',
    },
    {
      id: 'last_name',
      key: 'last_name',
      header: 'APELLIDO',
    },
    {
      id: 'relationship',
      key: 'relationship',
      header: 'PARENTESCO',
    },
    {
      id: 'birthdate',
      key: 'birthdate',
      header: 'FECHA DE NACIMIENTO',
    },
  ];
  public columnsDeclaration: DataTableColumn[] = [
    {
      id: 'name',
      key: 'name',
      header: 'NOMBRE',
    },
    {
      id: 'type_declaration',
      key: 'type_declaration',
      header: 'TIPO DE DECLARACIÓN',
    },
    {
      id: 'detail',
      key: 'detail',
      header: 'DETALLE',
    },
  ];
  public noDataMessage = 'NO EXISTEN PERSONAS AGREGADAS AL GRUPO FAMILIAR';
  public dataSource: DataTableSourceItem[] =
    JSON.parse(localStorage.getItem('stepTwoData') || '[]') || [];
  isLoading = false;

  constructor(
    private router: Router,
    private insuredDeclarationService: InsuredDeclarationService
  ) {
    this.stepperData[2].isActive = true;
  }

  ngOnInit(): void {}

  public goSuccess(): void {
    if (!this.completed) {
      this.completed = true;
      return;
    }
    this.callCreatePerson();
  }

  private callGetAllPersons(): void {
    this.insuredDeclarationService.getPeople().subscribe(
      (response) => {
        console.log(response);
      },
      (error) => {
        console.error(error);
      }
    );
  }
  private callCreatePerson(): void {
    const person: Person = this.getPersonInfo();
    // console.log(person);
    this.isLoading = true;
    person.declaration_type = this.declarationSelected;
    this.insuredDeclarationService.postPeople(person).subscribe(
      (personData) => {
        this.isLoading = false;
        this.router.navigate(['/success'], {
          state: { id: personData.data.id, email: this.oneStepData.email },
        });
      },
      (error) => {
        console.error(error);
      }
    );
  }

  private getPersonInfo(): Person {
    const familyGroup = JSON.parse(
      String(localStorage.getItem('stepTwoData')) || '{}'
    );
    const person = JSON.parse(
      String(localStorage.getItem('stepOneData')) || '{}'
    );
    if (Array.isArray(familyGroup)) {
      person.family_groups_attributes = familyGroup;
    } else {
      person.family_groups_attributes = [familyGroup];
    }
    person.region = person.region.region;

    const nameData =
      this.getFromStorage('name') || this.getFromStorageOne('name');
    const rutData = this.getFromStorage('rut') || this.getFromStorageOne('rut');
    const birthdateData = this.getFromStorageOne('birthdate');

    person.name = nameData;
    person.rut = rutData;
    person.birthdate = birthdateData;
    person.declaration_type = this.declarationSelected;
    return person;
  }

  public goEdit(): void {
    this.router.navigate(['/form']);
  }

  private getFromStorage(value: string): string {
    return JSON.stringify(localStorage.getItem(value) || '').replace(/"/g, '');
  }

  private getFromStorageOne(value: string): any {
    if (!localStorage.getItem('stepOneData')) {
      return '';
    }
    return JSON.parse(localStorage.getItem('stepOneData') || '')?.[value];
  }

  public addDeclaration() {
    this.declarations = [
      ...this.declarationData,
      {
        name: this.nameDeclaration,
        detail: this.detail,
        type_declaration: this.declarationSelected,
      },
    ];
    this.declarationData.push({
      name: this.nameDeclaration,
      detail: this.detail,
      type_declaration: this.declarationSelected,
    });
  }
}
