import { Injectable } from '@angular/core';
import {HttpClient} from "@angular/common/http";
import {Observable} from "rxjs";
import {ResponsePeopleData} from "../shared/interfaces/response-people-data.interface";
import {environment} from "../../environments/environment";
import {Person} from "./person.model";

@Injectable({
  providedIn: 'root'
})
export class WelcomeService {

  private URL_API = `https://api.libreapi.cl`;
  private URL_API_BACKEND = `${environment.BACKEND_URL}/api/v1/people-by-rut-code/`;

  constructor(private http: HttpClient) { }

  public getPerson(rut: string): Observable<{data: Person}> {
    return this.http.get<{data: Person}>(`${this.URL_API}/rut/activities?rut=${rut}`);
  }
  public getPersonByCode(rut: string, code: string): Observable<{ data: { attributes: Person } }> {
    return this.http.get<{data: { attributes: Person }}>(`${this.URL_API_BACKEND}?rut=${rut}&code=${code}`);
  }
}
