<div class="content" role="main">
  <h2 class="headline">
    BIENVENIDO AL FORMULARIO DE INCORPORACIÓN DE VIDA SECURITY
  </h2>
  <div class="insert">
    <div class="insert__title">
      <div class="insert__name">
        <div class="icon-person">
          <mat-icon
            [inline]="true"
            aria-hidden="false"
            aria-label=""
            color="primary"
            >account_circle</mat-icon
          >
        </div>
        <h3>HECTOR MARTINEZ PONTIGO</h3>
      </div>
      <div class="insert__document">
        <h4>Ingresa tu número de documento</h4>
        <div class="icon-help">
          <div
            #tooltip="matTooltip"
            matTooltip="Ingresa tu número de documento"
            [matTooltipPosition]="position.value"
            matTooltipHideDelay="100000"
            aria-label="Button that displays a tooltip that hides when scrolled out of the container"
          >
            <mat-icon
              [inline]="true"
              aria-hidden="false"
              aria-label=""
              color="primary"
              >help</mat-icon
            >
          </div>
        </div>
      </div>
      <mat-form-field class="input-form-field">
        <mat-label>{{ label }}</mat-label>
        <input matInput type="text" />
        <button
          mat-button
          matSuffix
          mat-icon-button
          aria-label="Ingresa tu número de documento"
          (click)="value = ''"
        >
          <mat-icon>close</mat-icon>
        </button>
      </mat-form-field>
      <!-- <app-input-clearable [label]="label"></app-input-clearable> -->
      <button mat-flat-button color="primary" (click)="goToInsertDataForm()">
        Ingresar
      </button>
    </div>
  </div>
</div>
