import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { PersonalInformationComponent } from './personal-information-form/personal-information.component';
import { InsertDocumentComponent } from './insert-document/insert-document.component';
import { WelcomeComponent } from './welcome/welcome.component';
import { SuccessfulSubscriptionComponent } from './successful-subscription/successful-subscription.component';
import { SummaryFamilyGroupComponent } from './summary-family-group/summary-family-group.component';
import { SummaryBeneficiaryComponent } from './summary-beneficiary/summary-beneficiary.component';
import { SummaryDeclarationsComponent } from './summary-declarations/summary-declarations.component';
import { SummaryInformationComponent } from './summary-information/summary-information.component';
import { InsuredDeclarationComponent } from './insured-declaration/insured-declaration.component';
import {DownloadDataComponent} from "./download-data/download-data.component";

const routes: Routes = [
  {
    path: '',
    component: WelcomeComponent
    //loadChildren: () => import('./welcome/welcome.module').then(m => m.WelcomeModule)
  },
  {
    path: 'document',
    component: InsertDocumentComponent
  },
  {
    path: 'form',
    component: PersonalInformationComponent
  },
  {
    path: 'family-group',
    component: SummaryFamilyGroupComponent
  },
  {
    path: 'beneficiary',
    component: SummaryBeneficiaryComponent
  },
  {
    path: 'summary-declarations',
    component: SummaryDeclarationsComponent
  },
  {
    path: 'information',
    component: SummaryInformationComponent
  },
  {
    path: 'insured-declaration',
    component: InsuredDeclarationComponent
  },
  {
    path: 'success',
    component: SuccessfulSubscriptionComponent
  },
  {
    path: 'download-base-data-colmed',
    component: DownloadDataComponent
  },
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule { }
