import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { MatSlideToggleChange } from '@angular/material/slide-toggle';
import { Router } from '@angular/router';
import { Person } from '../shared/interfaces/person.interface';
import { InsuredDeclarationService } from './insured-declaration.service';

@Component({
  selector: 'app-insured-declaration',
  templateUrl: './insured-declaration.component.html',
  styleUrls: ['./insured-declaration.component.scss']
})
export class InsuredDeclarationComponent implements OnInit {

  checked = false;
  color = 'primary';
  disabledButton = false;
  isLoading = false;
  oneStepData = JSON.parse(localStorage.getItem('stepOneData') || '');
  rut = localStorage.getItem('rut');
  name = localStorage.getItem('name');
  today = new Date().toDateString();

  constructor(private router: Router, private insuredDeclarationService: InsuredDeclarationService) { }

  ngOnInit(): void {
    this.callGetAllPersons();
  }

  public goSuccess() {
    this.callCreatePerson();
  }


  private callGetAllPersons() {
    this.insuredDeclarationService.getPeople().subscribe(
      (response) => { console.log(response); },
      (error) => { console.error(error); }
    );
  }
  private callCreatePerson() {
    const person: Person = this.getPersonInfo();
    this.isLoading = true;
    this.insuredDeclarationService.postPeople(person).subscribe(
      (personData) => {
        console.log(personData);
        this.isLoading = false;
        this.router.navigate(['/success'], { state: { id: personData.data.id, email: this.oneStepData.email } });
        },
      (error) => { console.error(error); }
    );
  }

  private getPersonInfo(): Person {
    const person: Person = {
      id: 1,
      name: 'Losd name',
      rut: '5464564546',
      birthdate: '5/12/2020',
      address: 'address asdsa',
      commune: 'commune asd',
      region: 'region dsa',
      email: 'qweqwe@sdsfdf.com',
      phone: '4587987',
      civil_status: 'civil_status asdddsa',
      health_system: 'health_system asddsa',
      position: 'positionasdda',
      hobbie: 'hobbieasdda',
      account_type: 'asada',
      bank: 'asdasd',
      account_number: 'asdad',
      status: 'asdasd',
      declaration_type: 'asdasd',
      declared_at: '5/12/2020',
      diagnostic_at: '5/12/2020',
      contract_at: '5/12/2020',
      family_groups_attributes: [],
      beneficiaries_attributes: [{
        name: 'asasd',
        relationship: 'asdad',
        rut: '545654654',
        percent: '545'
      }]
    };
    return person;
  }

  public goEdit() {
    this.router.navigate(['/form']);
  }

}
