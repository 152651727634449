import { Component, OnInit } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { Router } from '@angular/router';
import { AddFamilyMemberComponent } from './add-family-member/add-family-member.component';
import { StepperData } from '../shared/stepper/stepper-data.interface';
import { STEPS } from '../shared/utils';
import { AddFamilyMemberData } from './add-family-member/add-family-member-data.interface';
import { DataTableColumn, DataTableSourceItem } from '../shared/table/data-table/data-table.model';

@Component({
  selector: 'app-summary-family-group',
  templateUrl: './summary-family-group.component.html',
  styleUrls: ['./summary-family-group.component.scss']
})
export class SummaryFamilyGroupComponent implements OnInit {

  public stepperData: StepperData[] = STEPS;
  public familyData: AddFamilyMemberData;
  public columns: DataTableColumn[] = [
    {
      id: 'rut',
      key: 'rut',
      header: 'RUT'
    },
    {
      id: 'name',
      key: 'name',
      header: 'NOMBRE'
    },
    {
      id: 'relationship',
      key: 'relationship',
      header: 'PARENTESCO'
    },
    {
      id: 'birthdate',
      key: 'birthdate',
      header: 'FECHA DE NACIMIENTO'
    },
    {
      id: 'actions',
      key: 'actions',
      header: 'ACCIONES',
      custom: true
    },
  ];
  public noDataMessage: string = 'NO EXISTEN PERSONAS AGREGADAS AL GRUPO FAMILIAR';
  public dataSource: DataTableSourceItem[];
  public loadingTable = false;

  constructor(private router: Router, public dialog: MatDialog) {
    this.stepperData[1].isActive = true;
    this.dataSource = [];
    this.familyData = this.getEmptyFamilyData();

  }

  ngOnInit(): void {
    if (localStorage.getItem('stepTwoData')){
      this.dataSource = JSON.parse(localStorage.getItem('stepTwoData') || '{}');
    }
  }

  getEmptyFamilyData() {
    const empty: AddFamilyMemberData =
      { rut: '', name: '', birthdate: '', relationship: '', last_name: '' };
    return empty;
  }

  public goBack() {
    this.router.navigate(['/form']);
  }

  public goFinal() {
    this.router.navigate(['/information']);
  }

  public openAddFamilyMember() {
    const dialogRef = this.dialog.open(AddFamilyMemberComponent, {
      width: '600px',
      height: '590px',
      data: this.familyData
    });

    dialogRef.afterClosed().subscribe(result => {
      if (result) {
        this.loadingTable = true;
        this.dataSource = [...this.dataSource, result];
        localStorage.setItem('stepTwoData', JSON.stringify(this.dataSource));
        this.loadingTable = false;
      }
    });
  }

  editDialog(birthdate: string): void {
    const item = this.dataSource.find(itemSelected => itemSelected.birthdate === birthdate);

    const dialogRef = this.dialog.open(AddFamilyMemberComponent, {
      width: '600px',
      height: '590px',
      data: item,
    });

    dialogRef.afterClosed().subscribe(result => {
      if (result) {
        this.dataSource = this.dataSource.filter(itemSelected => itemSelected.birthdate !== birthdate);
        this.loadingTable = true;
        this.dataSource = [...this.dataSource, result];
        localStorage.setItem('stepTwoData', JSON.stringify(this.dataSource));
        this.loadingTable = false;
      }
    });
  }

  delete(birthdate: string): void{
    this.loadingTable = true;
    this.dataSource = this.dataSource.filter(item => item.birthdate !== birthdate);
    localStorage.setItem('stepTwoData', JSON.stringify(this.dataSource));
    this.loadingTable = false;
  }
}
