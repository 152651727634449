import { NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { MaterialModule } from './material.module';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { SharedModule } from './shared/shared.module';
import { PersonalInformationComponent } from './personal-information-form/personal-information.component';
import { SuccessfulSubscriptionComponent } from './successful-subscription/successful-subscription.component';
import { SummaryFamilyGroupComponent } from './summary-family-group/summary-family-group.component';
import { SummaryBeneficiaryComponent } from './summary-beneficiary/summary-beneficiary.component';
import { SummaryDeclarationsComponent } from './summary-declarations/summary-declarations.component';
import { SummaryInformationComponent } from './summary-information/summary-information.component';
import { InsuredDeclarationComponent } from './insured-declaration/insured-declaration.component';
import { AddFamilyMemberComponent } from './summary-family-group/add-family-member/add-family-member.component';
import { AddBeneficiaryComponent } from './summary-beneficiary/add-beneficiary/add-beneficiary.component';
import { HttpClientModule } from '@angular/common/http';
import { DataTableModule } from './shared/table/data-table/data-table.module';
import { WelcomeComponent } from './welcome/welcome.component';
import {NgxMaskModule} from "ngx-mask";
import { DownloadDataComponent } from './download-data/download-data.component';


@NgModule({
  declarations: [
    AppComponent,
    PersonalInformationComponent,
    SuccessfulSubscriptionComponent,
    SummaryFamilyGroupComponent,
    SummaryBeneficiaryComponent,
    SummaryDeclarationsComponent,
    SummaryInformationComponent,
    InsuredDeclarationComponent,
    AddFamilyMemberComponent,
    AddBeneficiaryComponent,
    WelcomeComponent,
    DownloadDataComponent
  ],
  exports: [
  ],
    imports: [
        BrowserModule,
        AppRoutingModule,
        BrowserAnimationsModule,
        MaterialModule,
        FormsModule,
        SharedModule,
        ReactiveFormsModule,
        HttpClientModule,
        DataTableModule,
        NgxMaskModule.forRoot(),
    ],
  providers: [NgxMaskModule],
  bootstrap: [AppComponent]
})
export class AppModule { }
