import { NgModule } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { BrowserModule } from '@angular/platform-browser';
import { InsertDocumentComponent } from '../insert-document/insert-document.component';
import { MaterialModule } from '../material.module';
import { StepperComponent } from './stepper/stepper.component';
import { MatStepperModule } from '@angular/material/stepper';

const modules = [
  InsertDocumentComponent,
  StepperComponent
];
@NgModule({
  declarations: [
    ...modules
  ],
  exports: [
    ...modules,
  ],
  imports: [
    BrowserModule,
    MaterialModule,
    FormsModule,
    MatStepperModule
  ],
  providers: [],
  bootstrap: []
})
export class SharedModule { }
