import { Component, Input, Output, EventEmitter, InjectionToken, OnInit, OnDestroy } from '@angular/core';
import { Subscription } from 'rxjs';

import { MOBILE, DESKTOP_TO_MOBILE, DeviceService } from '../device/device.service';

export let DEFAULT_VALIDATION_ERROR_MESSAGE = new InjectionToken<string>('DefaultValidationErrorMessage');

const DEFAULT_MESSAGE = 'Please fill out any fields highlighted in red with valid inputs.';

@Component({
  selector: 'app-action-buttons',
  templateUrl: 'action-buttons.component.html',
  styleUrls: ['action-buttons.component.scss']
})
export class ActionButtonsComponent implements OnInit, OnDestroy {
  mobile = false;
  @Input() cancelButtonText = 'Cancel';
  @Input() saveButtonText = 'Save';
  @Input() disableSaveButton = false;
  @Input() primaryButtonType = 'button';
  @Input() loading = false;
  @Input() validationErrorMessage: string = '';
  @Input() url: string = '';
  @Input() target = '_blank';
  @Input() showCancelButton = true;
  @Input() showInvalidInputsMessage = true;
  @Input() color = 'primary';
  @Output() canceled = new EventEmitter();
  @Output() saved = new EventEmitter();
  @Output() inputsHighlighted = new EventEmitter();
  showValidationError = false;
  private deviceSub: Subscription = new Subscription();

  constructor(private deviceService: DeviceService) { }

  ngOnInit() {
    this.validationErrorMessage = this.validationErrorMessage || DEFAULT_MESSAGE;

    this.deviceSub = this.deviceService.deviceChange.subscribe((device) => {
      this.mobile = (device === MOBILE || device === DESKTOP_TO_MOBILE);
    });
  }

  ngOnDestroy() {
    if (this.deviceSub && this.deviceSub.unsubscribe) {
      this.deviceSub.unsubscribe();
    }
  }

  cancel() {
    this.canceled.emit();
  }

  save() {
    this.saved.emit();
  }

  onInputsHighlighted() {
    this.showValidationError = true;
    this.inputsHighlighted.emit();
  }

  get errorMessage() {
    if (this.showInvalidInputsMessage && this.showValidationError && this.disableSaveButton) {
      return this.validationErrorMessage;
    } else {
      return '';
    }
  }
}
