<div class="content" role="main">
  <form
    class="insert__title"
    [formGroup]="addFamilyForm"
    (ngSubmit)="setFamilyMemberValues()"
  >
    <div class="insert__name">
      <h3 class="insert__border">Agregar información de familiar</h3>
    </div>
    <div class="insert__form">
      <mat-form-field class="insert__input">
        <h4>NOMBRE</h4>
        <input
          maxlength="255"
          matInput
          type="text"
          placeholder=""
          formControlName="name"
        />
        <mat-error *ngIf="name?.hasError('required')">
          Nombre es <strong>obligatorio</strong>.
        </mat-error>
      </mat-form-field>
      <mat-form-field class="insert__input">
        <h4>Apellido</h4>
        <input
          maxlength="255"
          matInput
          type="text"
          placeholder=""
          formControlName="last_name"
        />
        <mat-error *ngIf="last_name?.hasError('required')">
          Apellido es <strong>obligatorio</strong>.
        </mat-error>
      </mat-form-field>
    </div>
    <div class="insert__form">
      <mat-form-field class="insert__input">
        <h4>RUT (OPCIONAL)</h4>
        <input
          matInput
          type="text"
          placeholder=""
          formControlName="rut"
          maxlength="15"
          (input)="onValueChange()"
        />
        <mat-error
          *ngIf="rut?.hasError('rutinvalid') && !rut?.hasError('required')"
        >
          Ingrese un RUT válido
        </mat-error>
        <mat-error *ngIf="rut?.hasError('required')">
          RUT es <strong>obligatorio</strong>.
        </mat-error>
      </mat-form-field>
      <mat-form-field class="insert__input" appearance="legacy">
        <h4>PARENTESCO</h4>
        <mat-select formControlName="relationship">
          <mat-option
            *ngFor="let relationship of relationshipTypes"
            [value]="relationship"
            >{{ relationship }}</mat-option
          >
        </mat-select>
        <mat-error *ngIf="relationship?.hasError('required')">
          Parentesco es <strong>obligatorio</strong>.
        </mat-error>
      </mat-form-field>
    </div>

    <div class="insert__form">
      <mat-form-field class="insert__input">
        <h4>Fecha de nacimiento</h4>
        <input
          maxlength="255"
          matInput
          type="text"
          placeholder="01/12/1950"
          [showMaskTyped]="true"
          [dropSpecialCharacters]="false"
          mask="d0/M0/0000"
          formControlName="birthdate"
        />
        <mat-error *ngIf="birthdate?.hasError('required')">
          Fecha de nacimiento es <strong>obligatorio</strong>.
        </mat-error>
      </mat-form-field>
    </div>

    <!-- <app-input-clearable [label]="label"></app-input-clearable> -->
    <div class="insert__button-section insert__button-section--double">
      <button mat-flat-button color="secondary" (click)="onNoClick()">
        Cerrar
      </button>
      <!-- [disabled]="!personalInformationForm.valid" -->
      <button
        type="submit"
        mat-flat-button
        color="primary"
        (click)="setFamilyMemberValues()"
        [disabled]="!addFamilyForm.valid"
      >
        Agregar
      </button>
    </div>
  </form>
</div>
