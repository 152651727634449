<div class="content" role="main">
  <form
    class="insert__title"
    [formGroup]="addBeneficiaryForm"
    (ngSubmit)="callingFunction()"
  >
    <div class="insert__name">
      <h3 class="insert__border">Agregar información de beneficiario</h3>
    </div>
    <div class="insert__form">
      <mat-form-field class="insert__input">
        <h4>RUT</h4>
        <input
          maxlength="255"
          matInput
          type="text"
          placeholder=""
          formControlName="rut"
          maxlength="15"
          (input)="onValueChange()"
        />
        <mat-error
          *ngIf="rut?.hasError('rutinvalid') && !rut?.hasError('required')"
        >
          Ingrese un RUT válido
        </mat-error>
        <mat-error *ngIf="rut?.hasError('required')">
          RUT es <strong>obligatorio</strong>.
        </mat-error>
      </mat-form-field>
      <mat-form-field class="insert__input">
        <h4>NOMBRE</h4>
        <input
          maxlength="255"
          matInput
          type="text"
          placeholder=""
          formControlName="name"
        />
        <mat-error *ngIf="name?.hasError('required')">
          Nombre es <strong>obligatorio</strong>.
        </mat-error>
      </mat-form-field>
    </div>
    <div class="insert__form">
      <mat-form-field class="insert__input" appearance="legacy">
        <h4>PARENTESCO</h4>
        <mat-select formControlName="relationship">
          <mat-option
            *ngFor="let relationship of relationshipTypes"
            [value]="relationship"
            >{{ relationship }}</mat-option
          >
        </mat-select>
        <mat-error *ngIf="relationship?.hasError('required')">
          Parentesco es <strong>obligatorio</strong>.
        </mat-error>
      </mat-form-field>
    </div>

    <div class="insert__form">
      <mat-form-field class="insert__input">
        <h4>PORCENTAJE</h4>
        <input
          maxlength="3"
          matInput
          type="number"
          placeholder=""
          formControlName="percentage"
        />
        <mat-error *ngIf="percentage?.hasError('required')">
          Porcentaje es <strong>obligatorio</strong>.
        </mat-error>
      </mat-form-field>
    </div>

    <!-- <app-input-clearable [label]="label"></app-input-clearable> -->
    <div class="insert__button-section insert__button-section--double">
      <button mat-flat-button color="secondary" (click)="onNoClick()">
        Cerrar
      </button>

      <!-- [disabled]="!personalInformationForm.valid" -->
      <button
        type="submit"
        mat-flat-button
        color="primary"
        (click)="setBeneficiaryValues()"
        [disabled]="!addBeneficiaryForm.valid"
      >
        Agregar
      </button>
    </div>
  </form>
</div>
