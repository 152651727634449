// import { isUndefined } from 'lodash-es';

const PROPERTIES = [
  'cancelButtonText', 'saveButtonText', 'disableSaveButton', 'primaryButtonType', 'primaryButtonType', 'loading',
  'validationErrorMessage', 'url', 'target', 'showCancelButton', 'showInvalidInputsMessage'
];

export class ActionButtonsConfig {
  cancelButtonText = 'Cancel';
  saveButtonText = 'Save';
  disableSaveButton = false;
  primaryButtonType = 'button';
  loading = false;
  validationErrorMessage: string = '';
  url: string = '';
  target = '_blank';
  showCancelButton = true;
  showInvalidInputsMessage = true;

  constructor(partial: Partial<ActionButtonsConfig> = {}) {
  /*   PROPERTIES
      .filter(KEY => !isUndefined(partial[KEY]))
      .forEach(KEY => {
        this[KEY] = partial[KEY];
      }); */
  }
}
