<div class="edit-action-buttons" [class.mobile]="mobile">
  <app-button-group
    [errorMessage]="errorMessage"
    [orientation]="mobile ? 'top' : 'side'"
  >
    <app-button
      *ngIf="showCancelButton"
      (click)="cancel()"
      type="button"
      [disabled]="loading"
    >
      {{ cancelButtonText }}
    </app-button>
    <app-button
      [type]="primaryButtonType"
      [url]="url"
      [target]="target"
      [disabled]="disableSaveButton"
      [loading]="loading"
      (clicked)="save()"
      (inputsHighlighted)="onInputsHighlighted()"
    >
      {{ saveButtonText }}
    </app-button>
  </app-button-group>
</div>
