import { Injectable } from '@angular/core';
import { ReplaySubject, fromEvent, Observable } from 'rxjs';
import { startWith } from 'rxjs/operators';

export const MOBILE = 'mobile';
export const DESKTOP = 'desktop';
export const MOBILE_TO_DESKTOP = 'mobile_to_desktop';
export const DESKTOP_TO_MOBILE = 'desktop_to_mobile';

@Injectable()
export class DeviceService {
  currentDevice: string = '';
  windowSize: number = 0;
  private _deviceChange = new ReplaySubject<string>(1);
  private _windowResize = new ReplaySubject<number>(1);

  constructor() {
    //this.initObservable();
  }

  get deviceChange(): Observable<string> {
    return this._deviceChange.asObservable();
  }

  get windowResize(): Observable<number> {
    return this._windowResize.asObservable();
  }

  convertWidthToDeviceType(event: { target: { innerWidth: number } }): string {
    if (event.target.innerWidth < 768) {
      return MOBILE;
    } else {
      return DESKTOP;
    }
  }

  /*   initObservable(): void {
      fromEvent(window, 'resize')
        .pipe(startWith({ target: { innerWidth: window.innerWidth } }))
        .subscribe((event: { target: Window }) => {
          const device = this.convertWidthToDeviceType(event);
  
          this.windowSize = event.target.innerWidth;
          this._windowResize.next(this.windowSize);
  
          if (!this.currentDevice) {
            this.currentDevice = device;
            this._deviceChange.next(device);
          } else {
            if (device !== this.currentDevice) {
              if (this.currentDevice === DESKTOP) {
                this._deviceChange.next(DESKTOP_TO_MOBILE);
              } else {
                this._deviceChange.next(MOBILE_TO_DESKTOP);
              }
  
              this.currentDevice = device;
            }
          }
        });
    } */
}
