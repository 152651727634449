import { NgModule } from '@angular/core';
import { MatIconModule } from '@angular/material/icon';

import { EditButtonComponent } from './edit-button.component';

@NgModule({
  imports: [MatIconModule],
  declarations: [EditButtonComponent],
  exports: [EditButtonComponent]
})
export class EditButtonModule { }
