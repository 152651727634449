import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { environment } from 'src/environments/environment';
import { Person } from '../shared/interfaces/person.interface';
import { ResponsePeopleData } from '../shared/interfaces/response-people-data.interface';

@Injectable({
  providedIn: 'root'
})
export class InsuredDeclarationService {


  public URL_API = `${environment.BACKEND_URL}/api/v1`;

  constructor(private http: HttpClient) { }

  public getPeople(): Observable<{data: ResponsePeopleData[]}> {
    return this.http.get<{data: ResponsePeopleData[] }>(`${this.URL_API}/people`);
  }

  public postPeople(person: Person): Observable<{data: ResponsePeopleData}> {
    const body: Person = person;
    return this.http.post<{data: ResponsePeopleData}>(`${this.URL_API}/people`, body);
  }

  public postMassiveImport(persons: { rut: any; name: any; }[]): Observable<{data: ResponsePeopleData}> {
    return this.http.post<{data: ResponsePeopleData}>(`${this.URL_API}/massive-import`, {persons});
  }

  // const httpOptions: {
  //   headers: HttpHeaders;
  // } = {
  //   headers: new HttpHeaders({
  //     'Auth-Token': localStorage.getItem('Auth-Token')
  //   })
  // };

}
