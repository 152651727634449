<div class="content" role="main">
  <app-stepper [stepperData]="stepperData"></app-stepper>
  <form
    [formGroup]="personalInformationForm"
    class="insert__title"
    (ngSubmit)="setPersonalInfoValues()"
  >
    <div class="insert__name">
      <h3 class="insert__border">COMPLETA TU INFORMACIÓN PERSONAL</h3>
    </div>
    <div class="insert__form">
      <div class="insert__form">
        <mat-form-field class="insert__input">
          <h4>NOMBRE</h4>
          <input
            required
            matInput
            placeholder=""
            type="text"
            formControlName="name"
            color="primary"
            maxlength="255"
          />
          <mat-error *ngIf="name?.hasError('required')">
            Nombre es <strong>obligatorio</strong>.
          </mat-error>
        </mat-form-field>
      </div>
      <div class="insert__form">
        <mat-form-field class="insert__input">
          <h4>RUT</h4>
          <input
            required
            matInput
            placeholder=""
            type="text"
            formControlName="rut"
            color="primary"
            maxlength="255"
          />
          <mat-error *ngIf="rut?.hasError('required')">
            RUT es <strong>obligatorio</strong>.
          </mat-error>
        </mat-form-field>
      </div>
      <div class="insert__form date" *ngIf="width > 800">
        <mat-form-field class="insert__input">
          <h4>FECHA DE NACIMIENTO</h4>
          <input
            required
            matInput
            type="text"
            [showMaskTyped]="true"
            [dropSpecialCharacters]="false"
            mask="d0/M0/0000"
            placeholder="Ej: 01/12/1950"
            formControlName="birthdate"
            color="primary"
            maxlength="255"
          />
          <mat-error *ngIf="birthdate?.hasError('required')">
            Fecha de nacimiento es <strong>obligatorio</strong>.
          </mat-error>
        </mat-form-field>
      </div>
    </div>
    <div class="insert__form date" *ngIf="width <= 800">
      <mat-form-field class="insert__input">
        <h4>FECHA DE NACIMIENTO</h4>
        <input
          required
          matInput
          type="text"
          [showMaskTyped]="true"
          [dropSpecialCharacters]="false"
          mask="d0/M0/0000"
          placeholder="Ej: 01/12/1950"
          formControlName="birthdate"
          color="primary"
          maxlength="255"
        />
        <mat-error *ngIf="birthdate?.hasError('required')">
          Fecha de nacimiento es <strong>obligatorio</strong>.
        </mat-error>
      </mat-form-field>
    </div>
    <div class="insert__form">
      <mat-form-field class="insert__input">
        <h4>DIRECCIÓN</h4>
        <input
          required
          matInput
          placeholder=""
          type="text"
          formControlName="address"
          color="primary"
          maxlength="255"
        />
        <mat-error *ngIf="address?.hasError('required')">
          Dirección es <strong>obligatorio</strong>.
        </mat-error>
      </mat-form-field>
    </div>
    <div class="insert__form">
      <mat-form-field class="insert__input">
        <h4>REGIÓN</h4>
        <mat-select
          (selectionChange)="selectRegion($event.value)"
          formControlName="region"
        >
          <mat-option *ngFor="let region of regions" [value]="region">{{
            region.region
          }}</mat-option>
        </mat-select>
        <mat-error *ngIf="region?.hasError('required')">
          Región es <strong>obligatorio</strong>.
        </mat-error>
      </mat-form-field>
      <mat-form-field class="insert__input" appearance="legacy">
        <h4>COMUNA</h4>
        <mat-select formControlName="commune">
          <mat-option *ngFor="let commune of communes" [value]="commune">{{
            commune
          }}</mat-option>
        </mat-select>
        <mat-error *ngIf="commune?.hasError('required')">
          Comuna es <strong>obligatorio</strong>.
        </mat-error>
      </mat-form-field>
    </div>
    <div class="insert__form">
      <mat-form-field class="insert__input ng-touched mat-form-field-should-float">
        <h4>CORREO ELECTRÓNICO</h4>
        <input
          maxlength="255"
          matInput
          type="text"
          placeholder="tucorreo@dominio.com"
          formControlName="email"
          required
        />
        <mat-error
          *ngIf="email?.hasError('emailinvalid') && !email?.hasError('required')">
          Ingrese un correo válido
        </mat-error>
        <mat-error *ngIf="email?.hasError('required')">
          Correo electrónico es <strong>obligatorio</strong>.
        </mat-error>
      </mat-form-field>
      <mat-form-field class="insert__input">
        <h4>TELÉFONO</h4>
        <div class="insert__phone">
          <h5>+56</h5>
          <input
            maxlength="10"
            matInput
            type="number"
            placeholder=""
            formControlName="phone"
            required
          />
        </div>
        <mat-error *ngIf="phone?.hasError('required')">
          Teléfono es <strong>obligatorio</strong>.
        </mat-error>
      </mat-form-field>
    </div>
    <div class="insert__form">
      <mat-form-field class="insert__input" appearance="legacy">
        <h4>ESTADO CIVIL</h4>
        <mat-select formControlName="civil_status">
          <mat-option *ngFor="let status of maritalStatus" [value]="status">{{
            status
          }}</mat-option>
        </mat-select>
        <mat-error *ngIf="civil_status?.hasError('required')">
          Estado civil es <strong>obligatorio</strong>.
        </mat-error>
      </mat-form-field>
      <mat-form-field class="insert__input" appearance="legacy">
        <h4>SISTEMA DE SALUD</h4>
        <mat-select formControlName="health_system">
          <mat-option
            *ngFor="let healthcare of healthcareSystem"
            [value]="healthcare"
            >{{ healthcare }}</mat-option
          >
        </mat-select>
        <mat-error *ngIf="health_system?.hasError('required')">
          Sistema de salud es <strong>obligatorio</strong>.
        </mat-error>
      </mat-form-field>
    </div>
    <div class="insert__button-section">
      <button
        type="submit"
        [disabled]="!personalInformationForm.valid"
        mat-flat-button
        color="primary"
        (click)="goFamily()"
      >
        Avanzar
      </button>
    </div>
  </form>
</div>
