<div style="position: absolute; left:40%;">
  <button mat-stroked-button color="primary" (click)="downloadData()" style="margin-bottom: 20px; margin-top: 10px;">Descargar data</button>
  <br>
  <div style="border: 1px solid orange; cursor:pointer; border-radius: 2px; padding: 10px; width: 170px; height: 20px;" ng-app="MyApp" ng-controller="AppCtrl" layout-margin layout-padding>
    <input class="ng-hide" id="input-file-id" multiple type="file" (change)="uploadData($event.target.files)" />
    <label for="input-file-id" style="cursor:pointer" class="md-button md-raised md-primary">Cargar base de datos</label>
  </div>

</div>

<mat-spinner *ngIf="isLoading"></mat-spinner>
<h3 *ngIf="error">Error de credenciales</h3>
