<mat-card class="app-card mat-elevation-z0 outline">
  <div class="header--container" *ngIf="showHeader">
    <div>
      <h2 mat-card-title *ngIf="title">{{ title }}</h2>
      <h3 mat-card-subtitle *ngIf="subtitle">{{ subtitle }}</h3>
    </div>
    <app-edit-button *ngIf="showEditButton" (clicked)="onEditButtonClick()"></app-edit-button>
  </div>
  <img *ngIf="showCardImage" mat-card-image [src]="imageUrl">
  <mat-card-content>
    <ng-content></ng-content>
  </mat-card-content>
  <mat-card-actions *ngIf="showActions">
    <app-action-buttons
      *ngIf="actionButtonsConfig"
      [cancelButtonText]="actionButtonsConfig.cancelButtonText"
      [saveButtonText]="actionButtonsConfig.saveButtonText"
      [disableSaveButton]="actionButtonsConfig.disableSaveButton"
      [primaryButtonType]="actionButtonsConfig.primaryButtonType"
      [loading]="actionButtonsConfig.loading"
      [validationErrorMessage]="actionButtonsConfig.validationErrorMessage"
      [url]="actionButtonsConfig.url"
      [target]="actionButtonsConfig.target"
      [showCancelButton]="actionButtonsConfig.showCancelButton"
      [showInvalidInputsMessage]="actionButtonsConfig.showInvalidInputsMessage"
      (canceled)="onCancel()"
      (saved)="onSave()"
      (inputsHighlighted)="onInputsHighlight()"
    ></app-action-buttons>
  </mat-card-actions>
</mat-card>
