import {
  Component,
  OnInit,
  Input,
  ViewChild,
  OnChanges,
  ElementRef,
  AfterViewInit,
  TemplateRef,
  Output,
  EventEmitter
} from '@angular/core';
import { MatPaginator, MatPaginatorIntl } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';
import { SelectionModel } from '@angular/cdk/collections';
import { DataTableSourceItem, DataTableColumn } from './data-table.model';

@Component({
  selector: 'app-data-table',
  templateUrl: './data-table.component.html',
  styleUrls: ['./data-table.component.scss']
})
export class DataTableComponent implements OnInit, OnChanges, AfterViewInit {
  @Input() data: DataTableSourceItem[];
  @Input() totalCount: number;
  @Input() columns: DataTableColumn[];
  @Input() showFilter = false;
  @Input() hideHeader = false;
  @Input() allowSelection = false;
  @Input() showBorder = false;
  @Input() initialSelection = [];
  @Input() allowMultiSelect = false;
  @Input() templateRefs: TemplateRef<any>[];
  @Input() pageSize: number;
  @Input() loading = false;
  @Input() title: string;
  @Input() total: number;
  @Input() rowClass: string;
  @Output() searched = new EventEmitter();
  @Output() selectionChanged = new EventEmitter();
  @ViewChild('filterInput', { static: true }) filterInput: ElementRef;
  //@ViewChild(MatPaginator, { static: true }) paginator: MatPaginator;
  @ViewChild(MatSort, { static: true }) sort: MatSort;
  originalData: DataTableSourceItem[];
  selection: SelectionModel<DataTableSourceItem>;
  dataSource: MatTableDataSource<DataTableSourceItem>;
  @Input() showPagination = false;
  @Input() withFooter = false;
  @Input() noDataMessage = 'No data';

  constructor() {
    this.data = [];
    this.totalCount = 0;
    this.columns = [];
    this.templateRefs = [];
    this.pageSize = 0;
    this.title = '';
    this.total = 0;
    this.rowClass = '';
    this.filterInput = new ElementRef<any>({});
    this.dataSource = new MatTableDataSource();
    this.selection = new SelectionModel();
    this.originalData = [];
    this.sort = new MatSort();
    //this.paginator = {};
  }

  ngOnInit() {
    this.originalData = [...this.data];
    this.dataSource = new MatTableDataSource<DataTableSourceItem>(this.data);
    this.selection = new SelectionModel<DataTableSourceItem>(this.allowMultiSelect, this.initialSelection);
    this.selection.changed.subscribe(data => this.selectionChanged.emit(data.source.selected));
  }

  ngOnChanges(changes: { [key: string]: any }) {
    if (this.dataSource) {
      if (changes.data) {
        this.dataSource.data = this.data;
      }
    }
  }

  ngAfterViewInit() {
    this.sort.sortChange.subscribe(() => {
      this.onSearch();
    });

    // if (this.paginator !== undefined) {
    //   this.paginator.page.subscribe(() => {
    //     this.onSearch(this.paginator.pageIndex);
    //   });
    // }
  }

  get standardColumns() {
    return this.columns.filter(column => !column.custom);
  }

  get customColumns() {
    return this.columns.filter(column => column.custom);
  }

  get displayedColumns() {
    const baseColumns = [];

    if (this.allowSelection) {
      baseColumns.push('select');
    }

    return [...baseColumns, ...this.columns.map(column => column.id)];
  }

  onSearch(page: number = 0) {
    const { active, direction } = this.sort;

    let filter: string = '';

    if (this.showFilter) {
      filter = this.filterInput.nativeElement.value;
    }

    //this.paginator.pageIndex = page;
    this.searched.emit({ sort: { active, direction }, page, filter });
  }

  isAllSelected() {
    const numSelected = this.selection.selected.length;
    const numRows = this.dataSource.data.length;
    return numSelected === numRows;
  }

  masterToggle() {
    this.isAllSelected()
      ? this.selection.clear()
      : this.dataSource.data.forEach(row => this.selection.select(row));
  }
}
